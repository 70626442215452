@use "../../../styles/colors.scss" as colors;

.container {
    position: relative;
    height: 100%;
    border: 1px solid colors.$GRAY200;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: 8px;
    width: 100%;
}

.containerInner {
    padding: 20px 24px;
}

.line {
    width: 100%;
    height: 1px;
    margin: 8px 0;
    background-color: colors.$GRAY200;
}

.description {
    font-size: 14px;
    font-weight: 500;
    min-height: 80px;
    text-align: left;
    overflow: hidden;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.bottomContainerInner {
    height: 80px;
    overflow: auto;
}

.coursesContainer {
    align-self: flex-start;
    text-align: left;
    list-style-type: none;
    padding: 0;
    margin: 8px 0 0 0;
}

.courseWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: colors.$GRAY700;
    margin-bottom: 4px;
}

.courseIcon {
    margin-top: 4px;
    margin-right: 8px;
}

.courseLink {
    font-size: 12px;
    color: colors.$GRAY700;
}

.goalsDescContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
}

.goalsContainer {
    text-align: left;
    margin: 0;
    padding: 0;
    list-style-type: none;
}
