@use '../../../styles/colors' as colors;

.editableTextField {
    width: 100%;
    min-height: 130px;
}

.charactersCounter {
    color: colors.$GRAY600;
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
}

.selectsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    gap: 24px;
}