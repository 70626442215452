.icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.xsmall {
    width: 12px;
    height: 12px;
}

.small {
    width: 16px;
    height: 16px;
}

.medium {
    width: 20px;
    height: 20px;
}

.large {
    width: 24px;
    height: 24px;
}