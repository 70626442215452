@use '../../styles/responsive' as rwd; 
@use '../../styles/colors' as colors; 

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.languageSwitcherWrapper {
    align-self: flex-end;
    margin-bottom: 32px;
}

.logo {
    height: 76px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 72px;
    width: 418px;
}

.title {
    font-size: 24px;
    font-weight: 600;
}

.label {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: colors.$GRAY700 !important;
    margin-bottom: 6px;
}

.textField {
    width: 100%;
}

.loginButtonContainer {
    margin-top: 8px;
    width: 100%;
}

.footerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footerText {
    font-size: 14px;
    color: colors.$secondary;

    @include rwd.breakpoint(small) {
        order: 2;
        margin-top: 16px;
        margin-left: 0;
    }
}

.footerText2 {
    margin-left: 24px;
    font-size: 16px;

    @include rwd.breakpoint(small) {
        order: 1;
        margin-left: 0;
    }
}

.privatePolContainer {
    color: colors.$primary;
    text-decoration: none;
}

.registerText {
    text-align: center;
    margin-top: 24px;
    font-size: 14px;
    font-weight: 500;
}

.registerLink {
    text-decoration: none;
    color: colors.$PRIMARY700;
}