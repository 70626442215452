@use '../../../styles/sizes.scss' as sizes;
@use '../../../styles/colors.scss' as colors;

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
}

.areasContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
.area {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding: 4px;
    background-color: colors.$WHITE;
  }

.rightContainer {
    display: flex;
    flex-direction: row;
}

.NOT_REQUIRED_TO_KNOW {
    color: colors.$GRAY300;
}

.WANT_TO_KNOW {
    color: #88BD18;
}

.REQUIRED_TO_KNOW {
    color: #E21010;
} 

.actionItemWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid colors.$GRAY300;
    background-color: colors.$WHITE;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    box-shadow: 0px 1px 2px 0px #1018280D;
}

.firstActionItemWrapper {
    composes: actionItemWrapper;
    margin-right: 8px;
}