@use '../../styles/colors' as colors;

.modalContainer {
    display: flex;
}

.modalInnerContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: colors.$WHITE;
    padding: 32px;
    width: 500px;
    border-radius: 8px;
}

.title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 24px;
}

.subTitle {
    font-size: 14px;
    font-weight: 500;
}

.childrenContainer {
    margin-top: 12px;
}

.actionButtonsWrapper {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
}

.submitButtonContainer {
    margin-left: auto;
}