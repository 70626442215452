@use '../../styles/colors' as colors; 
@use '../../styles/responsive' as rwd; 

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.languageSwitcherWrapper {
    align-self: flex-end;
}

.title {
    font-size: 24px;
    font-weight: 600;
}

.logo {
    height: 76px;
}

.textWrapper {
    font-weight: 700;
    font-size: 24px;
    margin-right: 24px;

    @include rwd.breakpoint(small) {
        flex-direction: column;
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 418px;
    margin-top: 72px;
}

.title {
    font-size: 24px;
}

.registerButtonContainer {
    margin-top: 8px;
}


.textField {
    width: 100%;
}

.label {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: colors.$GRAY700 !important;
    margin-bottom: 6px;
}

.textFieldRadio {
    width: 277px;
}

.fieldErrors {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.errorMessage {
    color: #d32f2f;
    font-weight: 500;
}

.link {
    color: colors.$PRIMARY700;
}

.loginText {
    text-align: center;
    margin-top: 24px;
    font-size: 14px;
    font-weight: 500;
}

.loginLink {
    text-decoration: none;
    color: colors.$PRIMARY700;
}

.termsLabel {
    font-size: 14px;
    font-weight: 400;
}