.modalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalInnerContainer {
    width: 300px;
    height: 300px;
    background-color: white;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textarea {
    padding: 0;
    margin: 0 0 16px 0;
    width: 100%;
    height: 200px;
}

.iconButton {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0 2px 0 0;
    cursor: pointer;
  }