@use '../../styles/globals' as global; 
@use '../../styles/colors' as colors;
@use '../../styles/responsive' as rwd; 
// @import '../../styles/fonts.scss';

.container {
    position: sticky;
    top: 0px;
    z-index: 2222;
}

.routerLinkLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: colors.$primary;
}

.logo {
    height: 50px;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    flex-direction: row;
    border-bottom: 1px solid colors.$GRAY200;
    padding: 23px 48px;
    background-color: colors.$WHITE;
    max-width: 100%;

    @include rwd.breakpoint(small) {
        padding: 7px 16px;
    }
}

.leftWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.actionButtonsContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-left: 96px;
    position: relative;
}

.actionButtonContainer {
    position: relative;
}

.inputIcon {
    position: absolute;
    top: -6px;
    right: -6px;
    color: colors.$WHITE;
}

.rightWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: colors.$BLACK;
}