@use '../../../../styles/colors.scss' as colors;

.wrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    border: 1px solid colors.$GRAY200;
    border-radius: 12px;
    padding: 12px 14px;
}

.wrapperLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.title {
    font-size: 16px;
    font-weight: 600;
}

.counter {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid colors.$GRAY200;
    border-radius: 12px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
}

.wrapperRight {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}