.container {
    display: flex;
}

.columnsContainer {
    display: flex;
    flex-direction: row;
    gap: 32px;
    width: 100%;
    margin-top: 24px;
}

.column {
    border: 1px dashed gray;
    margin: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
}