@use '../../../styles/colors' as colors;

.editableTextField {
    width: 100%;
    min-height: 130px;
}

.charactersCounter {
    color: colors.$GRAY600;
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
}

.deadline {
    color: colors.$GRAY600;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
    margin-top: 32px;
}

.picker {
    width: 100%;
}