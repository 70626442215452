@use '../../styles/colors.scss' as colors; 

.wrapper {
    display: flex;
    flex-direction: row;
    border-radius: 16px;
    align-items: center;
    gap: 8px;
    border: 1px solid colors.$PRIMARY200;
    background-color: colors.$PRIMARY50;
    padding: 5px 8px;
    line-height: 18px;
}

.text {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    color: colors.$PRIMARY600;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}

.label {
    color: colors.$WHITE;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px;
    background-color: colors.$PRIMARY600;
    border: 1px solid colors.$PRIMARY200;
    border-radius: 16px;
}