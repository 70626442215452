$NORMAL: #ffcfe0;
$PRIMARY: #f5d22b;
$SECONDARY: #e793ba;
$DEFAULT: #fd9d49;
$QUANTENARY: #69c7c1;
$TERTIARY: #fff9b2;
$EMPTY: #000000;

$primary: #272E35;
$secondary: #555F6D;

//BASE
$WHITE: #FFFFFF;
$BLACK: #000000;

//GRAY
$GRAY25: #FCFCFD;
$GRAY50: #F9FAFB;
$GRAY100: #F2F4F7;
$GRAY200: #EAECF0;
$GRAY300: #D0D5DD;
$GRAY400: #98A2B3;
$GRAY500: #667085;
$GRAY600: #475467;
$GRAY700: #344054;
$GRAY800: #1D2939;
$GRAY900: #101828;

//PRIMARY
$PRIMARY25: #FCFAFF;
$PRIMARY50: #F9F5FF;
$PRIMARY100: #F4EBFF;
$PRIMARY200: #E9D7FE;
$PRIMARY300: #D6BBFB;
$PRIMARY400: #B692F6;
$PRIMARY500: #9E77ED;
$PRIMARY600: #7F56D9;
$PRIMARY700: #6941C6;
$PRIMARY800: #53389E;
$PRIMARY900: #42307D;

//ERROR
$ERROR25: #FFFBFA;
$ERROR50: #FEF3F2;
$ERROR100: #FEE4E2;
$ERROR200: #FECDCA;
$ERROR300: #FDA29B;
$ERROR400: #F97066;
$ERROR500: #F04438;
$ERROR600: #D92D20;
$ERROR700: #B42318;
$ERROR800: #912018;
$ERROR900: #7A271A;

//WARNING
$WARNING25: #FFFCF5;
$WARNING50: #FFFAEB;
$WARNING100: #FEF0C7;
$WARNING200: #FEDF89;
$WARNING300: #FEC84B;
$WARNING400: #FDB022;
$WARNING500: #F79009;
$WARNING600: #DC6803;
$WARNING700: #B54708;
$WARNING800: #93370D;
$WARNING900: #7A2E0E;

//SUCCESS
$SUCCESS25: #F6FEF9;
$SUCCESS50: #ECFDF3;
$SUCCESS100: #D1FADF;
$SUCCESS200: #A6F4C5;
$SUCCESS300: #6CE9A6;
$SUCCESS400: #32D583;
$SUCCESS500: #12B76A;
$SUCCESS600: #039855;
$SUCCESS700: #027A48;
$SUCCESS800: #05603A;
$SUCCESS900: #054F31;