@use '../../../styles/colors.scss' as colors;

.container {
    display: flex;
    align-self: flex-start;
    height: 100%;
    width: 100%;
    border: 1px solid colors.$PRIMARY200;
    box-shadow: 0px 1px 2px 0px #1018280D;
    border-radius: 8px;
}

.containerEmpty {
    padding: 20px 24px;
    margin: auto;
}

.description {
    margin-top: 52px;
    padding: 20px 24px;
}