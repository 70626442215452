@use '../../styles/colors' as colors;

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid colors.$GRAY200;
    padding: 0 0 42px 0;
}

.leftWrapper {
    min-width: 320px;
    margin-right: 32px;
}

.title {
    font-weight: 600;
    font-size: 30px;
}

.subTitle {
    font-weight: 400;
    font-size: 16px;
}

.midWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}