$page-width: 768px;
$page-width-medium: 1440px;
$page-width-large: 1440px;

@mixin list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin button-reset {
    border: none;
    margin: 0;
    padding: 0;
}


@mixin button() {
    @include button-reset;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}