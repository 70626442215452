@use '../../styles/colors.scss' as colors;

.tableCell {
    font-size: 12px;
    font-weight: 500;
}

.namesContainer {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
}

.role {
    font-weight: 400;
}

.modelsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}