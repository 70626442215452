@use '../../styles/sizes.scss' as sizes; 
@use '../../styles/colors.scss' as colors; 

.button {
    display: flex;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
    border: none;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.checked {
    border: 1px solid colors.$GRAY200;
}

.normal {
    border: 1px solid colors.$GRAY200;
    background-color: colors.$WHITE;
    color: colors.$GRAY700;
    font-weight: 500;
}
  
.text {
    font-size: 14px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    padding: 4px 12px;
}