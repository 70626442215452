.editableTextField {
    width: 100%;
    min-height: 130px;
}

.selectsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    gap: 24px;
}