@use '../../styles/colors' as colors;

.wrapper {
    display: flex;
    flex-direction: column;
}

.pageHeaderWrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid colors.$GRAY200;
    padding-bottom: 32px;
    margin-bottom: 40px;
}

.leftWrapper {
    min-width: 120px;
    width: 120px;
    margin-right: 32px;
}

.back {
  background-color: transparent;
  padding: 4px 8px;
  margin: 0 0 8px 0;
  width: 100px;
  border-radius: 8px;
}

.rightWrapper {
    width: 110%;
    box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.1); 
    padding: 8px;
}

.paginationContainer {
    margin-top: 54px;
}