@import './styles/fonts.scss';

* {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}