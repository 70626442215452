@use '../../styles/colors' as colors;

.wrapper {
    display: flex;
    flex-direction: column;
}

.pageHeader {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid colors.$GRAY200;
    padding: 32px 0;
    margin-bottom: 40px;
    align-items: center;
}

.pageAddGoalHeaderWrapper {
    composes: pageHeader;
    justify-content: space-between;
}

.pageEditableGoalHeaderWrapper {
    composes: pageHeader;
}

.rightWrapper {
    display: flex;
    flex-direction: row;
    gap: 32px;
}