@font-face {
    font-family: Inter;
    src: URL(../../public/fonts/Inter-Regular.ttf);
}

@font-face {
    font-family: Inter;
    src: URL(../../public/fonts/Inter-Medium.ttf);
}

@font-face {
    font-family: Inter;
    src: URL(../../public/fonts/Inter-SemiBold.ttf);
}