@use '../../../styles/responsive' as rwd; 
@use '../../../styles/colors' as colors; 

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 72px;
    width: 418px;
}

.title {
    font-size: 24px;
    font-weight: 600;
}

.label {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: colors.$GRAY700 !important;
    margin-bottom: 6px;
}

.textField {
    width: 100%;
}

.submitButtonContainer {
    margin-top: 8px;
    width: 100%;
}