@use '../../../styles/colors.scss' as colors;

.container {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    box-sizing: border-box;
    height: 244px;
    width: 100%;
}