@use '../../styles/colors' as colors;

.wrapper {
    display: flex;
    flex-direction: column;
}

.pageHeader {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid colors.$GRAY200;
    padding-bottom: 32px;
    margin-bottom: 40px;
}