@use '../../styles/colors' as colors;

.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 30px;
}

.subTitle {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 400;
    font-size: 16px;
    margin-top: 4px;
}

.badge {
    display: flex;
    padding: 0 12px;
    height: 26px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #7A5AF8;
    background-color: #F4F3FF;
    border: 1px solid #BDB4FE;
    margin-left: 16px;
}