@use '../../styles/colors' as colors;


.wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid colors.$GRAY200;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 16px;
    min-width: 384px;
}

.wrapperPadding {
    padding: 32px;
}

.header {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: colors.$GRAY500;
}

.subText {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: colors.$PRIMARY700;
    background-color: colors.$PRIMARY50;
    margin-left: 16px;
}

.title {
    margin: 16px 0;
}

.titleFirst {
    font-size: 60px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: -1.2px;
    color: colors.$GRAY900;
    margin-right: 4px;
}

.titleSecond {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: colors.$GRAY500;
}

.subTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: colors.$GRAY500;
    margin-bottom: 32px;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: colors.$GRAY200;
}

.attributesWrapper {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0 0 32px 0;
    margin: 0;
    gap: 16px;
}

.attribute {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: colors.$GRAY500;
    line-height: 24px;
}

.attributeIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 24px;
    background-color: colors.$PRIMARY100;
}

.attributeIcon {
    color: colors.$PRIMARY500;
}