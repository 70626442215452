@use '../../styles/sizes.scss' as sizes;
@use '../../styles/colors.scss' as colors;



.container {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
  width: 306px;
  height: 264px;
  border: 1px solid colors.$GRAY200;
  box-shadow: 0px 1px 2px 0px #1018280D;
}

.descriptionContainer {
  min-height: 80px;
  height: 80px;
  overflow: hidden; 
  align-self: flex-start;
}

.description {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.emptyItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 8px;
  width: 306px;
  height: 264px;
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.coursesContainer {
  height: 100px;
  overflow: auto;
  list-style-type: none;
  text-align: left;
  padding: 0;
  margin: 0;
  align-self: flex-start;
}

.courseWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: colors.$GRAY700;
  margin-bottom: 4px;
}

.courseIcon {
  margin-top: 4px;
  margin-right: 8px;
}

.courseLink {
    font-size: 12px;
    color: colors.$GRAY700;
}

.learnButton {
  width: 36px;
  height: 36px;
  border: 1px solid colors.$GRAY200;
  border-radius: 8px;
  align-self: flex-end;
  cursor: pointer;
  background-color: colors.$WHITE;
}

.learnButtonCorrect {
  width: 36px;
  height: 36px;
  border: 1px solid #88BD18;
  border-radius: 8px;
  align-self: flex-end;
  cursor: pointer;
  background-color: colors.$WHITE;
}

.toLearn {
  color: #88BD18;
}

.incorrectCheck {
  color: colors.$GRAY300;
}

.correctCheck {
  color: colors.$SUCCESS500;
}

.necessaryCheck {
  color: #E21010;
}

.learnButtonCheck {
  color: colors.$GRAY300;
}

.learnButtonCheckCorrect {
  color: #88BD18;
}