@use '../../styles/colors.scss' as colors;

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.createModalButtonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.matrixesWrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 32px;
    flex-wrap: wrap;
}

.matrixButton {
    display: flex;
    justify-content: center;
    align-items: center; 
    border: 1px solid colors.$GRAY200;
    box-shadow: 0px 1px 2px 0px #1018280D;
    background-image: linear-gradient(colors.$PRIMARY200, colors.$WHITE 100%);
    border-color: colors.$PRIMARY300;
    width: 150px;
    height: 150px;
    border-radius: 16px;
    cursor: pointer;
}

.addNewMatrix {
    display: flex;
    justify-content: center;
    align-items: center; 
    border: 1px solid colors.$SUCCESS300;
    background-image: linear-gradient(colors.$SUCCESS200, colors.$WHITE 100%);
    box-shadow: 0px 1px 2px 0px #1018280D;
    width: 150px;
    height: 150px;
    border-radius: 16px;
    cursor: pointer;
}