@use '../../../styles/colors.scss' as colors;

.menuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 240px;
}

.iconWrapper {
    display: flex;
    margin-right: 8px;
    color: colors.$GRAY500;
}

.nameWrapper {
    font-size: 14px;
    font-weight: 500;
    color: colors.$GRAY700;
}