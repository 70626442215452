@use '../../styles/colors' as colors; 

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid colors.$GRAY200;
    border-radius: 10px;
    padding: 11px;
    margin-right: 12px;
}

.titleMedium {
    font-weight: 700;
    font-size: 14px;
    color: colors.$GRAY900;
}

.titleHuge {
    font-weight: 600;
    font-size: 18px;
    color: colors.$GRAY900;
    margin-bottom: 4px;
}

.subTitle {
    font-weight: 400;
    font-size: 14px;
    color: colors.$GRAY700;
}

.titleSecondaryMedium {
    font-weight: 400;
    font-size: 14px;
    color: colors.$GRAY700;
}

.titleSecondaryHuge {
    font-weight: 400;
    font-size: 18px;
    color: colors.$GRAY700;
}

.subTitleSecondary {
    font-weight: 700;
    font-size: 14px;
    color: colors.$GRAY900;
}