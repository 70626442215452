@use '../../styles/colors' as colors;

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 56px 0 96px 0;
}

.blocksWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 96px;
    gap: 32px;
}

.header {
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.96px;
    color: colors.$GRAY900;
    margin-top: 12px;
}