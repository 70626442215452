.wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.normal {
    background-color: #E2E4E9;
    height: 4px;
    width: 79px;
}
.active {
    composes: normal;
    background-color: #E71D73;
}