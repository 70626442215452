@use '../../styles/colors' as colors;

.goalsButtonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.goalsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 48px;
    margin-left: 48px;
}

.editedContainer {
    margin-top: 24px;
}

.actionContainer {
    display: flex;
    flex-direction: row;
    gap: 424px;
}

.iconButton {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-top: 24px;
    margin-left: 10px;
}

.actionFirst {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-left: 8px;
}

.actionSecond {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-right: 8px;
}

.editableTextField {
    width: 100%;
}

.charactersCounter {
    color: colors.$GRAY600;
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
}

.deadline {
    color: colors.$GRAY600;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
    margin-top: 32px;
}

.picker {
    width: 100%;
}
