@use '../../styles/colors.scss' as colors; 

.wrapper {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    margin: auto;
    margin-top: 72px;
}

.templatesList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.singleModelWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    border: 1px solid colors.$PRIMARY600;
    padding: 8px 16px;
    border-radius: 16px;
    margin-bottom: 24px;
}

.label {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: colors.$GRAY700 !important;
}

.templateName {
    min-width: 200px;
}

.buttonsWrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
}