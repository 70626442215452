@use '../../styles/colors' as colors;

.wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 20px;
}

.filterLabelWrapper {
    display: flex;
    flex-direction: column;
}

.label {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
}

.inputWrapper {
    position: relative;
}

.inputIcon {
    position: absolute;
    top: 13px;
    left: 14px;
}

.inputNames {
    width: 262px;
    padding: 10px 14px 10px 42px;
    line-height: 24px;
    border-radius: 8px;
    border: 1px solid colors.$GRAY300;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 16px;
    font-weight: 400;
}

.selectPositionWrapper {
    width: 180px;
}

.selectLevelWrapper {
    width: 192px;
}

.filter {
    width: 100%;
    padding: 10px 14px;
    outline: none;
    line-height: 24px;
    border-radius: 8px;
    border: 1px solid colors.$GRAY300;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 16px;
    font-weight: 400;
}

.filter:focus {
    border: 1px solid colors.$GRAY400;
}