@use "../../styles/sizes.scss" as sizes;
@use "../../styles/colors.scss" as colors;

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.matrixNameContainer {
    margin-top: 24px;
}

.filtersContainer {
    display: flex;
    flex-direction: row;
    gap: 96px;
    margin-top: 28px;
    margin-bottom: 32px;
}

.itemsLegendContainer {
    display: flex;
    flex-direction: column;
}

.itemsLegendInnerContainer {
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0;
    margin: 24px 0 0 0;
}

.singleLegendRow {
    display: flex;
    align-items: center;
}

.itemsLegendTitle {
    min-width: 80px;
    width: 80px;
    background-color: colors.$NORMAL;
    text-align: center;
    font-size: sizes.$XLARGE;
    padding: 16px;
    border-radius: 8px;
}

.emptyItemContainer {
    flex: 1;
    width: 140px;
    margin-right: 8px;
}

.areasLegendContainer {
    display: flex;
    flex-direction: column;
}

.areasLegendInnerContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    gap: 8px;
    padding: 0;
    margin: 24px 0 0 0;
}

.areasLegendTitle {
    min-width: 80px;
    width: 80px;
    background-color: colors.$NORMAL;
    text-align: center;
    font-size: sizes.$XLARGE;
    padding: 16px;
    border-radius: 8px;
}

.sliderColumnsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.columnBasic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-rl;
    gap: 12px;
    height: 594px;
    margin-top: 80px;
    min-width: 100px;
}

.leftColumn {
    composes: columnBasic;
    margin-right: 10px;
    justify-content: flex-start;
}

.leftColumnMultiple {
    composes: columnBasic;
    margin-right: 10px;
}

.rightColumn {
    composes: columnBasic;
    margin-left: 10px;
    justify-content: flex-end;
}

.rightColumnMultiple {
    composes: columnBasic;
    margin-left: 10px;
}

.columnButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: 1px solid colors.$GRAY200;
    background-color: transparent;
    padding: 0 12px;
    border-radius: 12px;
    height: 100%;
    animation: fadeIn 0.5s ease forwards;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.draggableGridContainer {
    display: flex;
    flex-direction: row;
    margin: 24px auto 0 auto;
    max-width: 1256px;
}

.draggableGridVerticalContainer {
    display: flex;
    margin-top: 24px;
}

.secondMenuContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 80px;
}

.switchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
}

.generatingInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.generatingText {
    margin-top: 64px;
    font-size: 24px;
}

.notGeneratedError {
    margin-top: 64px;
    font-size: 24px;
}

.attitudesHeaderWrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.attitudesEditButton {
    border: none;
    margin: 0;
    padding: 2px 4px;
    border: 1px solid colors.$PRIMARY600;
    border-radius: 100px;
    background: transparent;
    cursor: pointer;
}
