@use '../../../styles/colors' as colors;


.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 56px 0 96px 0;
}

.subHeader {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: colors.$PRIMARY700
}

.header {
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.96px;
    color: colors.$GRAY900;
    margin-top: 12px;
}

.supportText {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: colors.$GRAY500;
    margin-top: 24px;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-color: colors.$PRIMARY50;
    margin-bottom: 72px;
}

.icon {
    color: colors.$PRIMARY700;
    height: 100px;
    width: 100px;
}