.editableTextField {
    width: 100%;
}

.rolesList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    min-height: 250px; // Żeby nie skakało
    margin-top: 16px;
}

.singleElementWrapper {
    padding: 4px;
    border-radius: 8px;
}

.actionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
