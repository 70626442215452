@use '../../styles/colors' as colors; 

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 145px;
}

.leftWrapper {
    position: relative;
    width: 50%;
    max-height: 100vh;
}

.leftLogoWrapper {
    position: absolute;
    top: 50px;
    left: 60px;
}

.leftFooterWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 50px;
    margin: 0 60px;
    text-wrap: nowrap;
}

.rightWrapper {
    display: flex;
    justify-content: flex-start;
    width: 50%;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100vh;
    width: 100%;
}

.footerText {
    color: colors.$PRIMARY25;
    font-size: 14px;
    font-weight: 500;

}

.footerText2 {
    color: colors.$PRIMARY25;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}