@mixin breakpoint($point) {
    @if $point == small {
        @media (max-width:  1024px) { @content; }
    }
    // @else if $point == medium {
    //     @media (min-width:  769px) and (max-width: 1280px) { @content; }
    // }
    @else if $point == large {
        @media (min-width:  1025px) { @content; }
    }
}