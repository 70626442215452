@use '../../styles/colors' as colors;
@use '../../styles/responsive' as rwd; 

.wrapper {
    display: flex;
    flex-direction: column;
    width: 418px;
    margin: auto;
    margin-top: 72px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.labelTooltipContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.label {
    font-size: 14px !important;
    font-weight: 500;
    color: colors.$GRAY700 !important;
    margin-bottom: 6px;
}

.labelRequired {
    color: #DF1C41;
    font-size: 14px;
    font-weight: 500;
}

.fileSizeNoteContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.fileSizeNote {
    font-size: 12px !important;
    font-weight: 500;
    color: colors.$GRAY500 !important;
    margin-bottom: 6px;
    // width: 100%;
    // text-overflow: wrap;
    // overflow-wrap: break-word;
    // word-wrap: break-word;
    // flex: 1;
    // white-space: normal;
    // overflow: visible;
}

.inputIcon {
    color: colors.$WHITE;
}

.title {
    font-size: 20px;
    font-weight: 700;
    margin-top: 72px;
}

.subTitle {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 24px;
}

.tooltip {
    margin-bottom: 4px;
}

.textField {
    width: 100%;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.editableTextField {
    width: 100%;
}

.actionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}

.iconButton {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    color: colors.$GRAY500;
    display: flex;
    align-items: center;
}

.iconDeleteButton {
    composes: iconButton;
    margin-left: 4px;
}

.createButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}

.cardContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.actionButtonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fileInput {
    display: none;
}

.tooltipFieldContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 26px 0;
}

.linksContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.linkContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.countButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2px;
}

.countButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2px;
}

.uploadsContainer {
    display: flex;
    flex-direction: column;
}

.uploadContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
}

.uploadButton {
    display: flex;
    flex-direction: column;
    border: 1px solid colors.$GRAY300;
    padding: 14px 10px;
    border-radius: 12px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.uploadIcon {
    color: colors.$GRAY600;
    border: 1px solid colors.$GRAY200;
    padding: 10px;
    border-radius: 12px;
}

.wholeSentence {
    color: colors.$GRAY600;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.firstSentence {
    color: colors.$PRIMARY700;
}

.stepTitle {
    font-size: 18px;
    margin-bottom: 32px;
    text-align: center;
}

.stepSubTitle {
    font-size: 16px;
    margin-bottom: 16px;
}

.firstStepButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

.thirdStepButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
}