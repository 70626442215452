
.column {
  border-radius: 8px;
  width: 100%;
}

.cardsWrapper {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 8px 0 0 0;
  gap: 8px;
}