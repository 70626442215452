@use '../../styles/colors' as colors;

.columnsContainer {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.columnsTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-right: 8px;
    box-shadow: 1px 0px 0px 0px rgba(16, 24, 40, 0.05);
    width: 100px;
}

.firstColumnTitleContainer {
    composes: columnsTitleContainer;
    border: 1px solid colors.$GRAY200;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: 0;
}

.middleColumnTitleContainer {
    composes:columnsTitleContainer;
    border: 1px solid colors.$GRAY200;
    border-top: 0;
    border-bottom: 0;
}

.lastColumnTitleContainer {
    composes: columnsTitleContainer;
    border: 1px solid colors.$GRAY200;
    border-top: 0;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    margin-bottom: 8px;
}

.aloneColumnTitleContainer {
    composes: columnsTitleContainer;
    border: 1px solid colors.$GRAY200;
    border-radius: 12px;
    margin-bottom: 8px;
}

.slider {
    width: 100%;
}

.levelNameWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    border: 1px solid colors.$GRAY200;
    padding: 12px 0;
    border-radius: 12px;
    margin-right: 8px;
    width: 100px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.levelName {
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
    margin-left: 14px;
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 0;
    margin: auto;
}

.columnLevelName {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
}

.itemsContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    gap: 8px;
}

.arrowBasic {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: none;
    border: 1px solid colors.$GRAY200;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
}


.prevArrow {
    composes: arrowBasic;
    position: absolute;
    top: 1px;
    margin-left: -55px;
}


.nextArrow {
    composes: arrowBasic;
    position: absolute;
    top: 1px;
    right: -55px;
}
