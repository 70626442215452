@use '../../styles/responsive' as rwd; 
@use '../../styles/globals' as settings; 

.wrapper {
    margin-left: auto;
    margin-right: auto;

    @include rwd.breakpoint(small) {
        max-width: settings.$page-width;
        padding: 16px 8px;
    }
    @include rwd.breakpoint(medium) {
        max-width: settings.$page-width-medium;
        padding: 32px;
    }
    @include rwd.breakpoint(large) {
        max-width: settings.$page-width-large;
        padding: 32px;
    }
}

.wrapperSkipPadding {
    composes: wrapper;
    padding: 0;
}