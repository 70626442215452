@use '../../styles/colors' as colors; 

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;    
    align-items: stretch;
    height: auto; 
    gap: 145px;
}

.leftWrapper {
    position: relative;
    flex: 1;
}

.leftLogoWrapper {
    position: absolute;
    top: 50px;
    left: 60px;
}

.leftFooterWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 50px;
    margin: 0 60px;
    text-wrap: nowrap;
}

.rightWrapper {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    margin: 32px 0;
}

.logo {
    height: 100%;
}

.footerText {
    color: colors.$PRIMARY25;
    font-size: 14px;
    font-weight: 500;

}

.footerText2 {
    color: colors.$PRIMARY25;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}