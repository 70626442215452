@use '../../styles/globals' as global; 
@use '../../styles/responsive' as rwd; 
@use '../../styles/colors' as colors; 

.button {
    @include global.button;

    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-radius: 8px;
    border: 1px solid;
    width: max-content;
    font-size: 16px;
    padding: 9px 13px;
}

.fullWidth {
    width: 100%;
}

.small {
    .leftIcon {
        padding-right: 8px;
    }

    .rightIcon {
        padding-left: 8px;
    }

    .singleStandIcon {
        padding: 0;
    }
}

.medium {
    .leftIcon {
        padding-right: 10px;
    }

    .rightIcon {
        padding-left: 10px;
    }

    .singleStandIcon {
        padding: 0;
    }
}

.large {
    .leftIcon {
        padding-right: 12px;
    }

    .rightIcon {
        padding-left: 12px;
    }

    .singleStandIcon {
        padding: 0;
    }
}

.primary {
    composes: button;
    color: colors.$GRAY700;
    background-color: colors.$WHITE;
    border-color: colors.$GRAY300;
}

.secondary {
    composes: button;
    font-size: 14px;
    font-weight: 600;
    height: max-content;
    color: colors.$WHITE;
    background-color: colors.$PRIMARY600;
    border-color: colors.$PRIMARY600;
}

.tertiary {
    composes: button;
    font-size: 14px;
    font-weight: 600;
    height: max-content;
    color: colors.$PRIMARY600;
    background-color: colors.$WHITE;
    border-color: colors.$PRIMARY600;
}

.helper {
    composes: button;
    font-size: 12px;
    font-weight: 400;
    height: max-content;
    padding: 0;
    color: colors.$PRIMARY600;
    background-color: colors.$WHITE;
    border: none;
}

.icon {
    composes: button;
    height: max-content;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
  }